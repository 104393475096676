import { curEnv, isDev, notProd } from "src/config";

export const PHOTO_LABEL_CREATION = curEnv !== "prod";

export const PROFILE_PHOTO_NUM_FACES = true;
export const CUSTOM_SUBDOMAIN = true;

export const WEB_SMS_CONSENT = true;
export const FILTER_SORT_MENU = true;

// Stable flags (clean up eventually)
export const HLS_STREAMING = true;
export const PHOTO_APPROVAL_CONTROLS_AVAILABLE = true;
export const CALLER_UPLOADS_SECTION = true;
export const DEDUPLICATE_PHOTOS_OPTION = true;
export const PROMPT_SHARE_AFTER_CREATE = true;
export const SHARE_PREVIEW = true;
export const GATHERING_QR_CODE = true;
export const SEND_ONLINE_UPDATES = true;
export const COMMUNITY_GATHERING_PINNING = true;
export const PUBLIC_API_ENABLED = true;
export const INLINE_CREATE_ALBUM = true;
export const TOP_PHOTOS = true;
export const REACTIONS = true;
export const PUBLIC_ALBUM_VIEWABLE = true;
export const STICKER_SELECTION = true;
export const GATHERING_RECAP = true;
export const AI_GENERATED_COVERS = true;
export const ADDONS = true;
export const REVIEW_PROMPT = true;
export const ADVANCED_THEME = true;

export const GATHERING_NOT_READY_POPUP = true;

// Dogfooding flags for internal use
export const EDIT_THEME_MENU = false && curEnv !== "prod";
/*
 * If you change this flag once this is GA, make sure you enable non-employee access in the backend
 */
export const PUBLIC_ALBUM_CONTROLS_AVAILABLE = PUBLIC_ALBUM_VIEWABLE && true && curEnv !== "prod";

export const BYPASS_EMPLOYEE_PROTECTION = true && isDev;

// Flags we won't be enabling for a while
export const SHOWCASE_ENABLED = false && notProd;
export const PHOTO_DETAIL_RECAP = false && notProd;
export const SHARED_ELEMENT = false && isDev;
/**
 * Whether or not to open photo in detail view when clicking on a photo in full public albums
 *
 * Does not effect whether or not the modal can be force opened (ex: from a link)
 */
export const FULL_PREVIEW_PHOTO_DETAIL = false;

export const WIPE_IMAGE_CACHE_ON_START = false && isDev;
export const DISABLE_PERSIST_APOLLO_CACHE = false && isDev;
export const DISABLE_NEXTJS_SERVER_CACHE = false && isDev;
export const FORCE_FILTER_LOADING = false && isDev;
export const DISABLE_LOG = false && isDev;
export const SANDBOX = false && isDev;
export const MUTUALS = false;
export const STORYBOOK = false && isDev;
export const TAG_BOUNDING_BOX = true && isDev;

/**
 * Simulates the API randomly breaking
 */
export const API_RANDOM_BREAKS = false && notProd;
export const UPLOAD_RANDOM_BREAKS = false && notProd;
