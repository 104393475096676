import { MotionStyle, m } from "framer-motion";
import { Ref, forwardRef, useMemo } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { MDivProps } from "./types";

const MDiv = (
	{ children, style: styleProp, pointerEvents, testID, className, ...props }: MDivProps,
	ref: Ref<HTMLDivElement>,
) => {
	const style = useMemo<MotionStyle>(() => {
		const flattenedStyleProp = StyleSheet.flatten(styleProp) as ViewStyle;

		const retStyle = StyleSheet.flatten([
			// React Native default styles. If you ever want to find these, just create a basic view and find the CSS
			{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				alignItems: "stretch",
				border: "0 solid black",
				boxSizing: "border-box",
				flexBasis: "auto",
				flexShrink: 0,
				listStyle: "none",
				margin: 0,
				minHeight: 0,
				minWidth: 0,
				padding: 0,
				textDecoration: "none",
				zIndex: 0,
			},
			{
				borderStyle:
					flattenedStyleProp?.borderStyle ??
					(flattenedStyleProp?.borderWidth ? "solid" : undefined),
				paddingLeft:
					flattenedStyleProp?.paddingLeft ?? flattenedStyleProp?.paddingHorizontal,
				paddingRight:
					flattenedStyleProp?.paddingRight ?? flattenedStyleProp?.paddingHorizontal,
				paddingTop: flattenedStyleProp?.paddingTop ?? flattenedStyleProp?.paddingVertical,
				paddingBottom:
					flattenedStyleProp?.paddingBottom ?? flattenedStyleProp?.paddingVertical,
				marginLeft: flattenedStyleProp?.marginLeft ?? flattenedStyleProp?.marginHorizontal,
				marginRight:
					flattenedStyleProp?.marginRight ?? flattenedStyleProp?.marginHorizontal,
				marginTop: flattenedStyleProp?.marginTop ?? flattenedStyleProp?.marginVertical,
				marginBottom:
					flattenedStyleProp?.marginBottom ?? flattenedStyleProp?.marginVertical,
				backgroundColor: flattenedStyleProp?.backgroundColor as string | undefined,
			},
			styleProp,
		]);
		if (!retStyle) return {};
		return retStyle as MotionStyle;
	}, [styleProp]);
	const resolvedClassName = useMemo(() => {
		const classNames: string[] = [];
		if (className) classNames.push(className);
		switch (pointerEvents) {
			case "box-none":
				classNames.push("pointerEvents-boxNone");
				break;
			case "box-only":
				classNames.push("pointerEvents-boxOnly");
				break;
			case "none":
				classNames.push("pointerEvents-none");
				break;
			case "auto":
				classNames.push("pointerEvents-auto");
				break;
			default:
				break;
		}
		return classNames.join(" ");
	}, [pointerEvents, className]);

	return (
		<m.div
			data-testid={testID}
			className={resolvedClassName}
			ref={ref}
			style={style}
			{...props}
		>
			{children}
		</m.div>
	);
};
export default forwardRef(MDiv);
