"use client";
import { useMemo } from "react";
import { AccessibilityRole, Platform, TextStyle } from "react-native";
import useDevice from "src/hooks/useDevice";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import fontFamilies from "src/styles/fontFamilies";
import { basicFont, largeFont, semiLargeFont, smallFont, xlFont, xsFont } from "src/styles/fonts";
import TextNative, { TextNativeProps } from "./components/TextNative";

export const LINE_HEIGHT_MULTIPLIER = 1.2;
export type AllTextProps = TextNativeProps & {
	color?: (string & {}) | keyof typeof color;
	bold?: boolean;
	italic?: boolean;
	semibold?: boolean;

	xs?: boolean;
	small?: boolean;
	sm?: boolean;
	basic?: boolean;
	semiLarge?: boolean;
	sl?: boolean;
	large?: boolean;
	lg?: boolean;
	xl?: boolean;
	h1?: boolean;
	h2?: boolean;
	h3?: boolean;
	h4?: boolean;
	h5?: boolean;
};

export type TextProps = Omit<TextNativeProps, "accessibilityRole"> & {
	italic?: boolean;
	color?: (string & {}) | keyof typeof color;
	"aria-level"?: 1 | 2 | 3 | 4 | 5 | 6;
	accessibilityRole?: "heading" | AccessibilityRole;
} & (
		| {
				bold?: boolean;
		  }
		| {
				semibold?: boolean;
		  }
	) &
	(
		| {
				xs?: boolean;
		  }
		| {
				small?: boolean;
		  }
		| {
				sm?: boolean;
		  }
		| {
				basic?: boolean;
		  }
		| {
				semiLarge?: boolean;
		  }
		| {
				sl?: boolean;
		  }
		| {
				large?: boolean;
		  }
		| {
				lg?: boolean;
		  }
		| {
				xl?: boolean;
		  }
		| {
				h1?: boolean;
		  }
		| {
				h2?: boolean;
		  }
		| {
				h3?: boolean;
		  }
		| {
				h4?: boolean;
		  }
		| {
				h5?: boolean;
		  }
	);
const Text = (props: TextProps) => {
	const { theme } = useStyle();
	const {
		children,
		color: colorProp,
		style,
		bold,
		italic,
		semibold,
		xs,
		small,
		sm,
		basic,
		semiLarge,
		sl,
		large,
		lg,
		xl,
		h1,
		h2,
		h3,
		h4,
		h5,
		allowFontScaling = false,
		accessibilityRole,
		...restProps
	} = props as AllTextProps;
	const { device } = useDevice();

	const combinedStyle = useMemo<TextStyle>(() => {
		var combinedStyle: TextStyle = {
			fontFamily: fontFamilies.regular,
			...basicFont,
			color: color.Text[theme],
		};
		if (colorProp) {
			combinedStyle.color =
				colorProp in color ? color[colorProp as keyof typeof color][theme] : colorProp;
		}
		if (bold) {
			combinedStyle.fontWeight = "bold";
		}
		if (semibold) {
			combinedStyle.fontWeight = "600";
		}
		if (italic) {
			combinedStyle.fontStyle = "italic";
		}
		if (xs) {
			combinedStyle.fontSize = xsFont.fontSize;
		}
		if (small || sm) {
			combinedStyle.fontSize = smallFont.fontSize;
		}
		if (basic) {
			combinedStyle.fontSize = basicFont.fontSize;
		}
		if (semiLarge || sl) {
			combinedStyle.fontSize = semiLargeFont.fontSize;
		}
		if (large || lg) {
			combinedStyle.fontSize = largeFont.fontSize;
		}
		if (xl) {
			combinedStyle.fontSize = xlFont.fontSize;
		}
		if (h1) {
			combinedStyle.fontSize = device === "mobile" ? 36 : 64;
		}
		if (h2) {
			combinedStyle.fontSize = device === "mobile" ? 25 : 48;
		}
		if (h3) {
			combinedStyle.fontSize = device === "mobile" ? 21 : 26;
		}
		if (h4) {
			combinedStyle.fontSize = device === "mobile" ? 17 : 31;
		}
		if (h5) {
			combinedStyle.fontSize = device === "mobile" ? 15 : 26;
		}

		if (Array.isArray(style)) {
			style.flat().forEach((item) => {
				if (typeof item === "object") {
					// @ts-expect-error
					combinedStyle = { ...combinedStyle, ...item };
				}
			});
		} else {
			if (typeof style === "object") {
				combinedStyle = { ...combinedStyle, ...style };
			}
		}

		const isBold =
			bold ||
			combinedStyle.fontWeight === "bold" ||
			String(combinedStyle.fontWeight) === "700";
		const isSemiBold = semibold || String(combinedStyle.fontWeight) === "600";
		const isItalic = italic || combinedStyle.fontStyle === "italic";
		if (isBold) {
			combinedStyle.fontFamily = fontFamilies.bold;
		}
		if (isSemiBold) {
			combinedStyle.fontFamily = fontFamilies.semibold;
		}
		if (isItalic) {
			combinedStyle.fontFamily = fontFamilies.italic;
		}
		if (isBold && isItalic) {
			combinedStyle.fontFamily = fontFamilies.boldItalic;
		}
		if (isSemiBold && isItalic) {
			combinedStyle.fontFamily = fontFamilies.semiboldItalic;
		}

		// On Android, must use Monserrat font families with regular font weight and font style
		if (Platform.OS === "android") {
			combinedStyle.fontWeight = "regular";
			combinedStyle.fontStyle = "normal";
		}

		return combinedStyle;
	}, [
		theme,
		colorProp,
		bold,
		semibold,
		italic,
		xs,
		small,
		sm,
		basic,
		semiLarge,
		sl,
		large,
		lg,
		xl,
		h1,
		h2,
		h3,
		h4,
		h5,
		style,
		device,
	]);

	return (
		<TextNative
			allowFontScaling={allowFontScaling}
			accessibilityRole={accessibilityRole as AccessibilityRole}
			{...restProps}
			style={[
				combinedStyle,
				{
					lineHeight:
						(combinedStyle.fontSize ?? basicFont.fontSize) * LINE_HEIGHT_MULTIPLIER,
				},
			]}
		>
			{children}
		</TextNative>
	);
};
export default Text;
