import useBlurHash from "./hooks/useBlurHash.web";

const WebBlurHashDisplay = ({ blurHash }: { blurHash: string }) => {
	const blurHashBase64 = useBlurHash(blurHash);

	if (!blurHashBase64) return null;
	return (
		<img
			src={blurHashBase64}
			alt={""}
			style={{
				position: "absolute",
				objectFit: "fill",
				width: "100%",
				height: "100%",
				zIndex: -1,
				filter: "blur(4px)",
				scale: 1.1,
			}}
		/>
	);
};
export default WebBlurHashDisplay;
