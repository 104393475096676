import { memo, useState } from "react";
import useParseViewPropsToWeb from "src/hooks/useParseViewPropsToWeb";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import "./loader.css";
import { NativeLoaderProps } from "./types";

const NativeLoader = ({ style, color = "white", size = 20, testID }: NativeLoaderProps) => {
	const [shiftS] = useState(
		() =>
			// 2400ms animation
			(getCurTime_MS() % 2400) / 1000,
	);
	const { style: parsedStyle = {} } = useParseViewPropsToWeb({
		style,
		pointerEvents: "auto",
	});

	return (
		<div
			// The timestamp synchronization causes hydration mismatches
			suppressHydrationWarning
			data-testid={testID}
			className="loader"
			style={{
				...parsedStyle,
				border: undefined,
				animationDelay: `-${shiftS}s`,
				// @ts-expect-error
				"--size": `${size}px`,
				"--color": color,
			}}
		/>
	);
};
export default memo(NativeLoader);
